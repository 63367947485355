import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import Card from "../components/card";
import FormattedText from "../components/portableText";

export const query = graphql`
    query LearningQuery {
        sanityGlobal(_id: { eq: "f4d0bdaa-f5e1-4bee-b8a5-4df771781c3e" }) {
            _rawResourcesIntro
        }
        allSanityLearningResource {
            edges {
                node {
                    resource_name
                    resource_url {
                        current
                    }
                    card_image {
                        asset {
                            gatsbyImageData
                        }
                    }
                    image {
                        asset {
                            gatsbyImageData
                        }
                    }
                    resource_description {
                        children {
                            text
                        }
                    }
                    tags {
                        project_tag
                    }
                    subject {
                        subject_tag
                    }
                    type
                }
            }
        }
    }
`;

const LearningResources = ({ data }) => {
    useEffect(() => {
        if (data.allSanityLearningResource) {
            document.body.classList.add("learning-resources");
        }
    });

    const [tag, updateTag] = useState([]);
    const [filterArray, setFilterArray] = useState([]);

    let currentTag = tag;

    let tagArray = [];
    data.allSanityLearningResource.edges.map((resource) =>
        resource.node.tags.forEach((tag) => {
            if (!tagArray.includes(tag.project_tag)) {
                tagArray.push(tag.project_tag);
            }
        })
    );
    tagArray.sort();

    let subjectArray = [];
    data.allSanityLearningResource.edges.map((resource) =>
        resource.node.subject.forEach((sub) => {
            if (!subjectArray.includes(sub.subject_tag)) {
                subjectArray.push(sub.subject_tag);
            }
        })
    );
    subjectArray.sort();

    const cards = data.allSanityLearningResource.edges;

    //handleClick  adds cards to the array
    const handleClick = (e) => {
        let item = e.target.id;
        if (!filterArray.includes(item)) {
            setFilterArray((arr) => [...arr, item]);
            e.target.classList.add("check");
        } else {
            return;
        }
    };

    // clearClick removes cards from the array
    const clearClick = (e) => {
        let clearitem = e.target.id.replace("clear", "");
        let position = filterArray.indexOf(clearitem);

        let updateArray = filterArray.map((clearitem, index) => {
            if (position !== index) {
                return clearitem;
            }
        });
        setFilterArray(updateArray);
        document.getElementById(clearitem).classList.remove("check");
    };

    // array for the filtered cards

    const filteredCards = [];

    if (currentTag.includes("all-cards")) {
        cards.forEach((card) => {
            card.node.tags.forEach((tagOnCard) => {
                filteredCards.push(card);
            });
        });
    } else {
        cards.forEach((card) => {
            card.node.tags.forEach((tagOnCard) => {
                if (filterArray.includes(tagOnCard.project_tag)) {
                    filteredCards.push(card);
                }
            });
        });
    }
    if (currentTag.includes("all-cards")) {
        cards.forEach((card) => {
            card.node.subject.forEach((tagOnCard) => {
                filteredCards.push(card);
            });
        });
    } else {
        cards.forEach((card) => {
            card.node.subject.forEach((tagOnCard) => {
                if (filterArray.includes(tagOnCard.subject_tag)) {
                    filteredCards.push(card);
                }
            });
        });
    }

    const dropdown = (e) => {
        e.target.parentNode.classList.toggle("active");
    };

    // clean filter array

    let cleanArray = filterArray.filter(function (el) {
        return el != null;
    });

    const resources = data.allSanityLearningResource.edges;
    let urlPath = "/learning-resource/";
    return (
        <Layout>
            <div>
                <StaticImage src="../images/hero-image.png" alt="Climate image" />
                <div className="container relative mx-auto text-center">
                    <div className="w-full pb-6 mx-auto sm:w-2/3 sm:pb-16">
                        <h1>Learning Resources</h1>
                    </div>
                </div>
            </div>
            <main id="main" className="p-6">
                {data.sanityGlobal._rawResourcesIntro ? (
                    <div className="container mx-auto">
                        <div className="w-full mx-auto mt-6 mb-24 text-center sm:w-2/3 lg:w-1/2">
                            <FormattedText blocks={data.sanityGlobal._rawResourcesIntro} />
                        </div>
                    </div>
                ) : null}

                <div className="container flex justify-center mx-auto">
                    Select workshop categories you are interested in
                </div>
                <div className="container flex justify-center p-4 mx-auto space-x-6 border-b-2 border-black border-solid sm:space-x-12 md:space-x-24">
                    <div className="filter-header">
                        <div id="ksgroup-dd" onClick={dropdown}>
                            KS Group
                        </div>
                        <div>
                            <ul className="" id="ksgroup-dd-list">
                                {tagArray.map((tagselect) => (
                                    <li className="tagselect" id={tagselect} onClick={handleClick}>
                                        {tagselect}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="filter-header">
                        <div id="subject-dd" onClick={dropdown}>
                            Subject
                        </div>
                        <div>
                            <ul className="" id="subject-dd-list">
                                {subjectArray.map((subjectselect) => (
                                    <li
                                        className="tagselect"
                                        id={subjectselect}
                                        onClick={handleClick}
                                    >
                                        {subjectselect}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container flex justify-center mx-auto">
                    {cleanArray.map((filteritem) => (
                        <button
                            className="filterButton"
                            id={filteritem + "clear"}
                            onClick={clearClick}
                        >
                            {filteritem}
                        </button>
                    ))}
                </div>

                <div className="container flex flex-wrap w-full p-6 mx-auto sm:w-11/12 md:w-5/6">
                    {filteredCards.length
                        ? filteredCards.map((resource, i) => (
                              <Card
                                  class="learning-resource card w-full sm:w-1/3 px-3 mb-6"
                                  imagedata={
                                      resource.node.card_image
                                          ? resource.node.card_image.asset.gatsbyImageData
                                          : null
                                  }
                                  alt={resource.node.resource_name}
                                  title={resource.node.resource_name}
                                  lrkstags={resource.node.tags}
                                  lrsubtags={resource.node.subject}
                                  type={resource.node.type}
                                  excerpt={resource.node.short_desc}
                                  link={urlPath + resource.node.resource_url.current}
                                  anchor="Read more  >"
                              />
                          ))
                        : data.allSanityLearningResource.edges.map((resource, i) => (
                              <Card
                                  class="learning-resource card w-full sm:w-1/3 px-3 mb-6"
                                  imagedata={
                                      resource.node.card_image
                                          ? resource.node.card_image.asset.gatsbyImageData
                                          : null
                                  }
                                  alt={resource.node.resource_name}
                                  title={resource.node.resource_name}
                                  lrkstags={resource.node.tags}
                                  lrsubtags={resource.node.subject}
                                  type={resource.node.type}
                                  excerpt={resource.node.short_desc}
                                  link={urlPath + resource.node.resource_url.current}
                                  anchor="Read more  >"
                              />
                          ))}
                </div>
            </main>
        </Layout>
    );
};

export default LearningResources;
